import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { mergeMap, of, catchError, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CashService {

  urlApi = environment.urlApi;

  constructor(private httpClient:HttpClient,private authService:AuthService) {}

  open(request:any){
    const url = `${this.urlApi}/cash/open-cash`;

    return this.httpClient.post<any>(url, request).pipe(
      mergeMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  close(request:any, cash_id:any){
    const url = `${this.urlApi}/cash/close-cash/${cash_id}`;

    return this.httpClient.put<any>(url, request).pipe(
      mergeMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  createInsertMoney(resquest:any, cash_open_id:number){
    const url = `${this.urlApi}/cash/insert-money/${cash_open_id}`;

    return this.httpClient.post<any>(url, resquest).pipe(
      mergeMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  checkCashOpening(){

    const url = `${this.urlApi}/cash/is-open/${this.cashOpened.id}`;

    return this.httpClient.get<any>(url).pipe(
      mergeMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  cleanCashOpening(){
    let branch = this.authService.branch;
    branch.cash.opening = null
    this.authService.branch = branch;
  }

  get cash() {
    const cash = localStorage.getItem('branch');
    return cash ? JSON.parse(cash).cash : null;
  }

  get cashOpened() {
    const cash = localStorage.getItem('branch');
    return cash ? JSON.parse(cash)?.cash?.opening : null;
  }

}
